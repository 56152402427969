import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "JWT Awesome Killer Use Cases",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:57:44.000Z",
  "url": "/2018/02/jwt-awesome-killer-use-cases/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`{.content-preview-title}`}</h1>
    <p>{`You got yourself your new feature to work on, another login system, log service, social plugin etc.`}</p>
    <p>{`While you are talking to your team about the new feature, dozens of thoughts and ideas are running through your mind and you just want to run back to your keyboard.`}</p>
    <p>{`Does that sound familiar?`}</p>
    <p>{`I assume it is, as this is what we all do Ha Ha 😂`}</p>
    <p>{`Now that far ago I discovered JWT, or in plain words:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Json Web Token`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "http://tzookb.com/what-the-hack-is-jwt/"
      }}>{`What The Hack is JWT ?!`}</a></p>
    <p>{`The first use case you get to think of and surely this is what the community is talking about is authentication.`}</p>
    <p>{`Surely JWT is a great tool for keeping your backend stateless and still authenticating your users.`}</p>
    <p>{`I’ll share with you 2 more features I implemented with JWT and maybe they could inspire you to more use cases that will help you solve more problems 🙂`}</p>
    <h6>{`Redirection With No Storage {#redirectionwithnostorage}`}</h6>
    <p>{`As we all have those link redirectors that simply track your visit and send you to the appropriate page.`}</p>
    <p>{`So the most basic one is having the destination inside the url and you are done.`}</p>
    <p>{`But what if someone inserts there a malicious url and then send the redirector url to users?`}</p>
    <p>{`So the users will think this link is solid as it is for a respective company like yourselves but then he gets redirected to who knows where`}{`…`}{`.`}</p>
    <p>{`Previous solution we had is simply having and ID in the url and when that ID appears we look it up in the DB and get the destination url.`}</p>
    <p>{`Works great and solves the problem.`}</p>
    <p>{`But`}{`…`}{`.`}</p>
    <p>{`Why should I waste precious time and resources for touching the DB??`}</p>
    <p>{`So I used the JWT for storing the destination url and the user ID.`}</p>
    <p>{`Now the backend gets the token, parses its validity and then redirects the user. No DB checks no nothing.`}</p>
    <p>{`Example for JWT data:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    dest_url: “https://tzookb.com”,
    user: 11,
    exp: 123232131
}
`}</code></pre>
    <p>{`You can see that I can easily get and track which user clicked the link.`}</p>
    <p>{`Where should I redirect him and if I want I can add expiration to make this link expire.`}</p>
    <h6>{`Authenticate From The Url {#authenticatefromtheurl}`}</h6>
    <p>{`Let’s say you have a forgot password process, and what we always did is store an hash key that will send the user and when he clicks the email link he will get redirected to a page with that key.`}</p>
    <p>{`Now in the backend we check that key in the DB and if it’s there we will allow the user to change passwords.`}</p>
    <p>{`But again we have an un needed DB call.`}</p>
    <p>{`We can use JWT again, and add the expiration to be in 2 hours, 24 hours whatever. So when the user clicks we can check for his JWT make sure it’s valid and assigned to him and we are good to go.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      